<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18 title-container">
            <span>Sale Prices</span>
          </h4>
          <div class="page-title-right">
            <div class="rowCount">
              <span class="legend statistic"></span> Purchase Statistic
              <span class="legend promotion"></span>Promotional Price
              <span class="legend notLatest"></span>Price Not from latest pricelist
            </div>
            <span class="rowCount">{{ this.totalRows.toLocaleString() }} Articles found</span>
            <span class="supplierFilter tag"
                  v-if="filteredSupplier"><strong>Supplier: </strong>{{ filteredSupplier.name }}  <b-button
                variant="outline-primary" size="sm" @click="resetSupplierFilter"><i
                class="bx bx-x"></i></b-button></span>
            <ol class="breadcrumb m-0">
              <li>
                <b-button @click="openSalePriceExportModal" variant="primary" class="mr-2">Export</b-button>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col-12">
        <SalePricesGrid/>
      </div>
    </div>
    <ExportSalePricesModal />
    <AddArticlePriceModal/>
    <DeactivateArticleModal />
  </Layout>
</template>

<script>
import Layout from '../layouts/horizontal.vue';
import {mapState} from "vuex";
import AddArticlePriceModal from "../components/articles/AddArticlePriceModal";
import DeactivateArticleModal from "../components/articles/DeactivateArticleModal";
import ExportSalePricesModal from "../components/articles/ExportSalePricesModal.vue";
import SalePricesGrid from "../components/articles/SalePricesGrid.vue";

export default {
  name: 'Benchmarks',
  components: {
    SalePricesGrid,
    ExportSalePricesModal,
    DeactivateArticleModal,
    AddArticlePriceModal,
    Layout
  },
  created() {
    this.$store.dispatch('ancillaries/fetchSuppliers');
  },
  computed: mapState({
    totalRows: (state) => state.articles.articleLastRow,
    filteredSupplier: (state) => state.articles.filterSupplier
  }),
  methods: {
    openSalePriceExportModal() {
      this.$store.dispatch('articles/openSalePriceExportModal');
    },
    resetSupplierFilter() {
      this.$store.dispatch('articles/clearSupplierFilter');
    }
  }
}
</script>

