<script>

export default {
  name: 'PriceArticleDisplayCell',
  data: function () {
    return {
      renderValue: '',
      articleData: false,
      buttonsVisible: false
    };
  },
  beforeMount() {

  },
  mounted() {

    if (! this.params.data) {
      return;
    }

    if (this.params.data) {
      this.articleData = this.params.data

      if (this.params.data.title) {
        this.renderValue = this.params.data.title.title;
        return;
      }
    }

    if (! this.params.value) {
      if (this.params.data.article) {
        this.renderValue = this.params.data.article.title;
      }

      if (this.params.data.supplier_article) {
        this.renderValue = this.params.data.supplier_article.name;
      }
    }

  },
  methods: {
    async deletePrice() {
      let decision = window.confirm("Are you sure you want to delete this price?");

      if (decision) {
        const response = await this.$store.dispatch('prices/deletePrice', this.articleData.id);
        response.then(() => {

        });
      }
    },
    copyPrice() {
      const copyText = this.articleData.title;
      navigator.clipboard.writeText(copyText).then(() => {});
    }
  }
};
</script>

<template>
  <div @mouseover="buttonsVisible = true" @mouseleave="buttonsVisible = false" class="articleDisplay"><span class="articleDisplay__text">{{ renderValue }}</span>
    <b-button size="sm" v-if="buttonsVisible" variant="danger" @click="deletePrice" class="articleDisplay__button" :title="`Remove Price #${this.articleData.id}`"><b-icon-x></b-icon-x></b-button>
    <b-button size="sm" v-if="buttonsVisible" variant="secondary" @click="copyPrice" class="articleDisplay__button mx-1" title="Copy Title"><b-icon-clipboard-plus></b-icon-clipboard-plus></b-button>
  </div>
</template>

<style lang="scss">
.articleDisplay {
  display: flex;
  align-items: center;

  &__text {
    margin-right: 10px;
    display: inline-block;
  }

  &__button {

    color: #fff;
    border: 0;
    display: flex;
    align-items: center;
    font-size: 12px;
    height: 23px;
    width: 23px;
    justify-content: center;
  }
}
</style>